import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

const PhoneNumberInput = ({ value, handleFieldChange, placeholder }) => {
  const [phoneNumber, setPhoneNumber] = useState(value);

  useEffect(() => {
    setPhoneNumber(value);
  }, [value]);

  return (
    <PhoneInput
      inputProps={{
        name: "phoneNumber",
        required: true,
        autoFocus: true,
      }}
      country={"gb"}
      onlyCountries={["gb", "es", "ae"]} // Restrict to UK & Spain
      value={phoneNumber}
      key={/^\+\d{2}0.*$/.test(phoneNumber)}
      onChange={(phone, countryData, e) => {
        let newPhone = phone.startsWith("+") ? phone : `+${phone}`; // Ensure '+' is always there
        let needToUpdate = false;
        // Ensure correct country code if the user removes it
        if (!newPhone.startsWith(`+${countryData.dialCode}`)) {
          if (newPhone.startsWith(`+${countryData.dialCode}0`)) {
            newPhone = newPhone.replace(
              `+${countryData.dialCode}0`,
              `+${countryData.dialCode}`
            );
            needToUpdate = true;
          }
          newPhone = `+${countryData.dialCode}${newPhone
            .replace(/\D/g, "")
            .slice(countryData.dialCode.length)}`;
        } else {
          if (newPhone.startsWith(`+${countryData.dialCode}0`)) {
            newPhone = newPhone
              .replace(`+${countryData.dialCode}0`, `+${countryData.dialCode}`)
              .replace(/\D/g, "");
            needToUpdate = true;
          }
        }
        if (needToUpdate) {
          setPhoneNumber("");
        }
        setPhoneNumber(newPhone);
        handleFieldChange(newPhone, countryData);
      }}
      placeholder={placeholder}
      autoFormat={false}
    />
  );
};

export default PhoneNumberInput;
