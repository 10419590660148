import _ from "lodash";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { Dialog, DialogContent, Slide, Switch } from "@mui/material";
import { ArrowDown, CrossIcon, DeleteIconRed } from "assets";
import SalaryRangeSlider from "component/SalaryRangeSlider";
import FileUpload from "component/FileUpload";
import Stepper from "component/Stepper";
import {
  formatNumberInProperForm,
  generateRandomId,
  handleDownloadSampleCsv,
  validatePhoneNumber,
} from "utils/common";
import {
  extractPdfDetail,
  fetchExtractedDetailByReferenceId,
} from "services/Company";
import MultipleFileUpload from "component/UploadMultiplePdfFiles";
import CallInfo from "dialogs/callInfoDialog";
import PhoneNumberInput from "component/PhoneNumberField";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewJob = (props) => {
  const {
    open,
    setOpen,
    handleCreateJob,
    mode = "add",
    jobData,
    editState,
  } = props;

  const jobCreationSteps = ["Job Details", "Candidates", "Call Details"];

  const [step, setStep] = useState(editState === "edit_call" ? 2 : 0);
  const [jobDetail, setJobDetail] = useState({
    title: _.get(jobData, "title", ""),
    workplaceType: _.get(jobData, "workspaceType", "on-site"),
    expectedExperience: _.get(jobData, "expectedExperience", 1),
    location: _.get(jobData, "location", ""),
    annualSalary:
      _.get(jobData, "minSalary", 0) && _.get(jobData, "maxSalary", 0)
        ? [jobData.minSalary, jobData.maxSalary]
        : [80000, 120000],
    prompt: _.get(jobData, "prompt", ""),
    language: _.get(jobData, "language", "en"),
  });
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });
  const [uploadedCandidates, setUploadedCandidates] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [manualAccordian, setManualAccordian] = useState(false);
  const [manualUploadValues, setManualUploadValues] = useState([
    { firstName: "", lastName: "", phoneNumber: "+44", id: generateRandomId() },
  ]);
  const [errors, setErrors] = useState([
    // manual upload fields value error
    { firstName: "", lastName: "", phoneNumber: "" },
  ]);
  // New state to handle validation errors
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    location: "",
    manualDetail: "",
    fileInfo: "",
    prompt: "",
    cvError: "",
    extractDetailError: "",
    language: "",
  });
  const [uploadMode, setUploadMode] = useState("cv");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isDetailExtracted, setIsDetailExtracted] = useState(false);
  const [isExtractInfoDialog, setIsExtractInfoDialog] = useState(false);

  const errorMsg = {
    title: "Job Title is required",
    location: "Location is required",
    manualDetail: "Please fill up required detail",
    fileInfo: "CSV or Excel file must be uploaded",
    prompt: "Promt detail is required",
    firstName: "First name is required",
    lastName: "Last name is required",
    cvFiles: "Cv files is required",
    extractDetail: "Extract detail is required",
    language: "Language is required",
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        cvError: "",
        extractDetailError: "",
      }));
    }
  }, [uploadedFiles]);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleChangeJobDetails = (field, value) => {
    setJobDetail((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear validation errors dynamically when user types
    // if (validationErrors[field]) {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     [field]: "",
    //   }));
    // }
    if (field !== "prompt") {
      setValidationErrors((prev) => ({
        ...prev,
        [field]: value ? "" : errorMsg[field],
      }));
    }
  };

  const validateField = (name, value) => {
    let errMsg = "";

    if (!(value && value.trim())) {
      errMsg = errorMsg[name];
    } else if (name === "phoneNumber") {
      errMsg = validatePhoneNumber(value);
    }

    return errMsg;
  };

  const handleFieldChange = (index, name, value) => {
    const updatedFields = [...manualUploadValues];

    updatedFields[index][name] = value;

    setManualUploadValues(updatedFields);

    // Dynamically validate the field
    const updatedErrors = [...errors];
    updatedErrors[index][name] = validateField(
      name,
      updatedFields[index][name]
    );
    setErrors(updatedErrors);
  };

  const handleFieldBlur = (index, name) => {
    const updatedErrors = [...errors];
    updatedErrors[index][name] = validateField(
      name,
      manualUploadValues[index][name]
    );
    setErrors(updatedErrors);
  };

  const validateAllFields = () => {
    const nonEmptyValues = manualUploadValues.filter(
      (field) =>
        field?.firstName?.trim() ||
        field?.lastName?.trim() ||
        field?.phoneNumber?.trim()
    );

    if (nonEmptyValues.length === 0) {
      setManualUploadValues([
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);

      const newErrors = [
        { firstName: "", lastName: "", phoneNumber: "+44" },
      ].map((field) => ({
        firstName: validateField("firstName", field.firstName),
        lastName: validateField("lastName", field.lastName),
        phoneNumber: validateField("phoneNumber", field.phoneNumber),
      }));

      setErrors(newErrors);
      return false;
    }

    setManualUploadValues(nonEmptyValues);

    const newErrors = nonEmptyValues.map((field) => ({
      firstName: validateField("firstName", field.firstName),
      lastName: validateField("lastName", field.lastName),
      phoneNumber: validateField("phoneNumber", field.phoneNumber),
    }));

    setErrors(newErrors);

    // Check if all manualUploadValues are valid
    return newErrors.every(
      (error) => !error.firstName && !error.lastName && !error.phoneNumber
    );
  };

  const handleAddFields = () => {
    // clear if anything uploaded through file
    setUploadedCandidates([]);
    setFileInfo({ name: "", size: 0 });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      fileInfo: "", // Clear file error
    }));

    if (validateAllFields()) {
      setManualUploadValues([
        ...manualUploadValues,
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);
      setErrors([...errors, { firstName: "", lastName: "", phoneNumber: "" }]);
    }
    const element = document.getElementById("add-field-button");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleRemoveField = (index, isManualList = false) => {
    let manualUploadCopy = [...manualUploadValues];
    let errorsCopy = [...errors];
    manualUploadCopy.splice(index, 1);
    errorsCopy.splice(index, 1);
    setManualUploadValues(manualUploadCopy);
    setErrors(errorsCopy);
    if (manualUploadCopy.length === 0) {
      isManualList ? setManualAccordian(false) : setIsDetailExtracted(false);
    }
  };

  const renderInputField = (index, name, placeholder, label, type = "text") => (
    <div
      className={`w-full flex flex-col gap-1 flex-1 ${
        name === "phoneNumber" ? "common-manual-upload common-tel-phone" : ""
      } ${errors[index]?.[name] ? "error-tel-phone" : ""}`}
    >
      <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px]">
        {label}
      </label>
      {name === "phoneNumber" ? (
        <PhoneNumberInput
          handleFieldChange={(value) => handleFieldChange(index, name, value)}
          value={manualUploadValues[index][name]}
          placeholder="Ex +44 7700 900000"
        />
      ) : (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={manualUploadValues[index][name]}
          onChange={(e) => handleFieldChange(index, name, e.target.value)}
          onBlur={() => handleFieldBlur(index, name)}
          className={`input text-gray-700 bg-white border ${
            errors[index]?.[name] ? "border-red-500" : "border-gray-300"
          } rounded-lg py-4 px-4 block w-full outline-none`}
        />
      )}
      {errors[index]?.[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[index][name]}
        </div>
      )}
    </div>
  );

  const validateStep1 = () => {
    const errors = {};
    if (!jobDetail.title) {
      errors.title = errorMsg.title;
    }
    if (!jobDetail.location) {
      errors.location = errorMsg.location;
    }
    return errors;
  };

  const validateStep2 = () => {
    console.log("fileInfo", fileInfo);
    const errors = {};

    if (fileInfo.name) {
      return errors;
    }

    if (uploadMode === "cv") {
      if (!isDetailExtracted && uploadedFiles.length === 0) {
        errors.cvError = errorMsg.cvFiles;
      } else if (!isDetailExtracted && uploadedFiles.length > 0) {
        errors.extractDetailError = errorMsg.extractDetail;
      } else if (isDetailExtracted && !validateAllFields()) {
        errors.manualDetail = errorMsg.manualDetail;
      }
    } else {
      if (!fileInfo.name && manualAccordian) {
        if (!validateAllFields()) {
          errors.manualDetail = errorMsg.manualDetail;
        }
      } else if (!fileInfo.name) {
        errors.fileInfo = errorMsg.fileInfo;
      }
    }

    return errors;
  };

  const validateStep3 = () => {
    const errors = {};
    if (!jobDetail.prompt) {
      errors.prompt = errorMsg.prompt;
    }
    return errors;
  };

  const handleNextStep = () => {
    let errors = {};
    if (step === 0) {
      errors = validateStep1();
    } else if (step === 1) {
      errors = validateStep2();
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      setStep(step + 1); // Move to the next step if no errors
    }
  };

  const renderStep1Content = () => {
    return (
      <>
        <div className="flex flex-col gap-[6px] lg:mb-4 mb-3">
          <label
            htmlFor="jobtitle"
            className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f]"
          >
            Title of the job you're hiring for
          </label>
          <input
            value={jobDetail.title}
            id="jobtitle"
            type="text"
            placeholder="Ex Project Manager"
            className="input text-gray-700 bg-white disabled:bg-[#e5e5e599] border border-gray-300 rounded-lg py-[14px] leading-5 h-12 px-4 w-full outline-none"
            onChange={(event) =>
              handleChangeJobDetails("title", event.target.value)
            }
            disabled={mode === "edit"}
          />
          {validationErrors.title && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.title}
            </span>
          )}
        </div>

        <div className="flex lg:flex-row flex-col gap-5 mb-4">
          <div className="flex flex-col gap-[6px] flex-1">
            <label
              htmlFor="large"
              className="text-[15px] leading-5 font-normal text-[#1f1f1f] "
            >
              Workplace Type
            </label>
            <select
              id="large"
              className="w-full h-12 pl-[16px] pr-[14px] py-[14px] text-base text-gray-900 border border-[#E5E5E5] rounded-lg focus-visible:outline-none"
              onChange={(event) =>
                handleChangeJobDetails("workplaceType", event.target.value)
              }
              value={jobDetail.workplaceType}
            >
              <option value="office">Office</option>
              <option value="remote">Remote</option>
              <option value="hybrid">Hybrid</option>
            </select>
          </div>

          <div className="flex flex-col gap-[6px] flex-1">
            <label
              htmlFor="expectedExperience"
              className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f] "
            >
              Expected Experience
            </label>
            <input
              value={jobDetail.expectedExperience}
              id="expectedExperience"
              type="number"
              min={0}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] h-12 px-4 w-full outline-none"
              onChange={(event) =>
                handleChangeJobDetails("expectedExperience", event.target.value)
              }
            />
          </div>
        </div>

        <div className="flex flex-col gap-[6px] lg:mb-4 mb-3">
          <label
            htmlFor="location"
            className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f] "
          >
            Location
          </label>
          <input
            value={jobDetail.location}
            id="location"
            type="text"
            placeholder="Ex London, UK"
            className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] h-12 px-4 w-full outline-none"
            onChange={(event) =>
              handleChangeJobDetails("location", event.target.value)
            }
          />
          {validationErrors.location && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.location}
            </span>
          )}
        </div>

        <SalaryRangeSlider
          value={jobDetail.annualSalary}
          setValue={(value) => handleChangeJobDetails("annualSalary", value)}
        />
        <div className="md-3 mt-2">
          <label className="block text-sm font-medium mb-2" htmlFor="language">
            Language (to be used in the call)
          </label>
          <select
            id="language"
            value={jobDetail.language}
            onChange={(event) =>
              handleChangeJobDetails("language", event.target.value)
            }
            className="w-full h-12 pl-[16px] pr-[14px] py-[10px] text-base text-gray-900 border border-[#E5E5E5] rounded-lg focus-visible:outline-none"
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
          </select>
          {validationErrors.language && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.language}
            </span>
          )}
        </div>
      </>
    );
  };

  const handleAccordionClick = () => {
    setManualAccordian(!manualAccordian);
    if (manualUploadValues.length === 0) {
      setManualUploadValues([
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);
    }
    if (errors.length === 0) {
      setErrors([{ firstName: "", lastName: "", phoneNumber: "" }]);
    }
    setValidationErrors((prev) => ({
      ...prev,
      fileInfo: "",
    }));
    // Add smooth scroll after state update
    if (!manualAccordian) {
      setTimeout(() => {
        const scrollToElement = document.getElementById("add-field-button");
        if (scrollToElement) {
          scrollToElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  const renderStep2Content = () => {
    return (
      <div className="flex flex-col">
        <div className="flex items-center gap-1 justify-end">
          <Switch
            checked={uploadMode === "csv"}
            onChange={(e) => handleUploadingModeChange(e)}
            color="primary"
          />
          <span className="text-sm font-medium text-gray-700">
            {uploadMode === "cv" ? "CSV  Upload" : "CSV  Upload"}
          </span>
        </div>
        <div className="flex flex-col gap-5">
          {uploadMode === "csv" ? (
            <>
              <div className="flex flex-col gap-1">
                <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
                  Upload CSV
                </h2>
                <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
                  Please upload a CSV file with the candidates names and contact
                  numbers.{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={handleDownloadSampleCsv}
                  >
                    Click here
                  </span>{" "}
                  to see a template of what it should look like.
                </h3>
              </div>
              <div className="flex flex-col gap-3">
                <>
                  <FileUpload
                    fileInfo={fileInfo}
                    setFileInfo={(file) => {
                      setFileInfo(file);
                      // Clear file validation error if file is uploaded
                      if (file && file.name) {
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          fileInfo: "", // Clear file error
                        }));
                        setManualUploadValues([
                          {
                            firstName: "",
                            lastName: "",
                            phoneNumber: "+44",
                            id: generateRandomId(),
                          },
                        ]);
                        setErrors([
                          { firstName: "", lastName: "", phoneNumber: "" },
                        ]);
                        setManualAccordian(false);
                      }
                    }}
                    setUploadedCandidates={setUploadedCandidates}
                  />
                  {validationErrors.fileInfo && (
                    <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                      {validationErrors.fileInfo}
                    </span>
                  )}
                </>
              </div>
            </>
          ) : (
            <Fragment>
              <div className="flex flex-col gap-1">
                <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
                  Upload CVs (PDF)
                </h2>
                <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
                  Upload multiple CVs in PDF format to extract candidate
                  details.
                </h3>
              </div>
              <div className="flex flex-col gap-2">
                <MultipleFileUpload
                  setUploadedFiles={setUploadedFiles}
                  setFileList={setFileList}
                  fileList={fileList}
                  addMoreBtnDisabled={isFetching}
                />
                {validationErrors.cvError && (
                  <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                    {validationErrors.cvError}
                  </span>
                )}
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleExtractDetails}
                    className="h-[40px] px-6 bg-custom-primary text-white rounded-lg disabled:opacity-75"
                    disabled={isFetching || uploadedFiles.length === 0}
                  >
                    {isExtractInfoDialog && (
                      <CallInfo
                        HideKeyStorage={"hideExtractPopup"}
                        open={isExtractInfoDialog}
                        setOpen={setIsExtractInfoDialog}
                        handleSubmit={() => {}}
                        infoDetail={{
                          heading: "PDF Extraction in Progress",
                          innerContent:
                            "Your PDF is currently being processed. This may take 1-2 minutes. Please wait while we extract the necessary information.",
                        }}
                      />
                    )}
                    {isFetching ? "Extracting..." : "Extract Details"}
                  </button>
                </div>
              </div>
            </Fragment>
          )}

          {uploadMode === "csv" ? (
            <div>
              <div className="flex justify-center items-center text-[#1f1f1f66] relative before:content-[''] before:absolute before:top-1/2 before:left-0 before:h-[1px] before:w-[calc(50%_-_20px)] before:bg-[#E5E5E5] after:content-[''] after:absolute after:top-1/2 after:right-0 after:h-[1px] after:w-[calc(50%_-_20px)] after:bg-[#E5E5E5]">
                OR
              </div>
              <div className="border-b-[1px] border-[#EFEFEF] py-3">
                <div
                  className="cursor-pointer flex justify-between items-center text-[16px] font-medium text-[#1F1F1F] leading-[22px]"
                  onClick={handleAccordionClick}
                >
                  Upload Manually
                  {manualAccordian ? (
                    <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
                      <img
                        src={ArrowDown}
                        alt="ArrowUp"
                        className="rotate-180"
                      />
                    </div>
                  ) : (
                    <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
                      <img src={ArrowDown} alt="ArrowDown" />
                    </div>
                  )}
                </div>

                <div
                  className={`mt-3 transition-all duration-700 ease-in-out overflow-hidden ${
                    manualAccordian
                      ? "max-h-full opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="flex flex-col gap-4">
                    {manualUploadValues.map((field, index) => (
                      <div
                        key={_.get(field, "id", index)}
                        className="w-full flex items-center gap-1"
                      >
                        <div className="flex w-full items-start gap-2">
                          {renderInputField(
                            index,
                            "firstName",
                            "Ex John",
                            "First Name"
                          )}
                          {renderInputField(
                            index,
                            "lastName",
                            "Ex Doe",
                            "Last Name"
                          )}
                          {renderInputField(
                            index,
                            "phoneNumber",
                            "Ex +44 24 3421 331",
                            "Phone Number"
                          )}
                        </div>
                        <div
                          className={`min-w-7 min-h-7 mt-[29px] flex justify-center items-center cursor-pointer ${
                            _.get(errors, `[${index}].firstName`, "") ||
                            _.get(errors, `[${index}].phoneNumber`, "") ||
                            _.get(errors, `[${index}].lastName`, "")
                              ? "mb-6"
                              : ""
                          }`}
                          onClick={() => handleRemoveField(index, true)}
                        >
                          <img
                            src={DeleteIconRed}
                            alt="Delete Icon"
                            width={28}
                            height={28}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end mt-3 w-[calc(100%_-_44px)]">
                    <button
                      type="button"
                      id="add-field-button"
                      onClick={handleAddFields}
                      className="h-[36px] w-[72px] flex justify-center items-center bg-custom-primary text-white rounded-lg py-[10px] px-6"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              {validationErrors.manualDetail && (
                <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                  {validationErrors.fileInfo}
                </span>
              )}
            </div>
          ) : (
            uploadMode === "cv" &&
            isDetailExtracted && (
              <Fragment>
                <div className="mt-3 transition-all duration-700 ease-in-out overflow-hidden max-h-full opacity-100">
                  <div className="flex flex-col gap-4">
                    {manualUploadValues.map((field, index) => (
                      <div
                        key={_.get(field, "id", index)}
                        className="w-full flex items-center gap-4"
                      >
                        <div class="flex w-full items-start gap-4">
                          {renderInputField(
                            index,
                            "firstName",
                            "Ex John",
                            "First Name"
                          )}
                          {renderInputField(
                            index,
                            "lastName",
                            "Ex Doe",
                            "Last Name"
                          )}
                          {renderInputField(
                            index,
                            "phoneNumber",
                            "Ex +44 24 3421 331",
                            "Phone Number"
                          )}
                        </div>
                        <div
                          className={`min-w-7 min-h-7 mt-[29px] flex justify-center items-center cursor-pointer ${
                            _.get(errors, `[${index}].firstName`, "") ||
                            _.get(errors, `[${index}].phoneNumber`, "") ||
                            _.get(errors, `[${index}].lastName`, "")
                              ? "mb-6"
                              : ""
                          }`}
                          onClick={() => handleRemoveField(index)}
                        >
                          <img
                            src={DeleteIconRed}
                            alt="Delete Icon"
                            width={28}
                            height={28}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {validationErrors.manualDetail && (
                  <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                    {validationErrors.fileInfo}
                  </span>
                )}
              </Fragment>
            )
          )}

          {uploadMode === "cv" && validationErrors.extractDetailError && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.extractDetailError}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderStep3Content = () => {
    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="text-base text-[#1F1F1F] leading-[22px] -tracking-[1%] font-medium">
            Train our AI agent
          </h2>
          <h3 className="text-[#1F1F1F] text-[13px] leading-5 -tracking-[1%] font-light">
            Use this space for additional context for your AI agent. It will
            help them tailor the conversation with the details you want. You can
            write multiple prompts.
          </h3>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px]">
            Prompt Details for a Call
          </label>
          <textarea
            name="Prompt Details for a Call"
            rows="7"
            placeholder={`Ex "Make sure you ask about notice period"`}
            value={jobDetail.prompt || ""}
            onChange={(event) => {
              handleChangeJobDetails("prompt", event.target.value);
            }}
            className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
          />
          {validationErrors.prompt && (
            <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.prompt}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    setValidationErrors({});
    handleCreateJob({
      title: jobDetail.title,
      workspaceType: jobDetail.workplaceType,
      expectedExperience: jobDetail.expectedExperience,
      location: jobDetail.location,
      language: jobDetail.language,
      minSalary: jobDetail.annualSalary[0],
      maxSalary: jobDetail.annualSalary[1],
      status: "active",
      prompt: jobDetail.prompt,
      candidates:
        fileInfo?.name && uploadedCandidates.length > 0
          ? uploadedCandidates
          : manualUploadValues.map((m) => ({
              firstName: m.firstName,
              lastName: m.lastName,
              phoneNumber: m.phoneNumber,
            })),
    });
  };

  const handleEditSubmit = async () => {
    let errors = {};
    if (editState === "edit_job_details") {
      errors = validateStep1();
    }
    // else if (editState === "edit_call") {
    //   errors = validateStep3()
    // }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setSubmitLoading(true);
    setValidationErrors({});
    await handleCreateJob(
      editState === "edit_job_details"
        ? {
            title: jobDetail.title,
            workspaceType: jobDetail.workplaceType,
            expectedExperience: jobDetail.expectedExperience,
            location: jobDetail.location,
            language: jobDetail.language,
            minSalary: jobDetail.annualSalary[0],
            maxSalary: jobDetail.annualSalary[1],
            prompt: jobDetail.prompt,
          }
        : {
            title: jobDetail.title,
            workspaceType: jobDetail.workplaceType,
            expectedExperience: jobDetail.expectedExperience,
            location: jobDetail.location,
            language: jobDetail.language,
            minSalary: jobDetail.annualSalary[0],
            maxSalary: jobDetail.annualSalary[1],
            prompt: jobDetail.prompt,
          }
    );
    setSubmitLoading(false);
  };

  const handleUploadingModeChange = (e) => {
    setUploadMode(e.target.checked ? "csv" : "cv");
    setUploadedFiles([]);
    setUploadedCandidates([]);
    setManualUploadValues(
      e.target.checked
        ? [
            {
              firstName: "",
              lastName: "",
              phoneNumber: "+44",
              id: generateRandomId(),
            },
          ]
        : []
    );
    setValidationErrors({});
    setFileList([]);
    setErrors([{ firstName: "", lastName: "", phoneNumber: "" }]);
    setManualAccordian(false);
  };
  const handleExtractDetails = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    const hidePopup = localStorage.getItem("hideExtractPopup");
    if (!hidePopup || hidePopup !== "true") {
      setIsExtractInfoDialog(true);
    }
    try {
      setIsFetching(true);
      setValidationErrors({});
      const response = await extractPdfDetail(formData);

      if (response.data.data.reference_id) {
        pollExtractedDetails(response.data.data.reference_id);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsFetching(false);
    }
  };
  const pollExtractedDetails = async (referenceId) => {
    try {
      const data = await fetchExtractedDetailByReferenceId(referenceId);
      if (data && data.length > 0) {
        // Data fetched successfully, stop polling
        const extractedData = data.map((details) => {
          const parsedDetail = details.extractedDetails
            ? JSON.parse(details.extractedDetails)
            : {};
          return {
            id: generateRandomId(),
            firstName: parsedDetail.first_name,
            lastName: parsedDetail.last_name,
            phoneNumber: parsedDetail.phone_number || "+44",
          };
        });

        console.log("extractedData", extractedData);

        const dataWithFormattedPhoneNumber =
          formatNumberInProperForm(extractedData);
        console.log("Formatted Data:", dataWithFormattedPhoneNumber);
        setManualUploadValues(dataWithFormattedPhoneNumber);
        setErrors(
          extractedData.map((m) => ({
            firstName: "",
            lastName: "",
            phoneNumber: "",
          }))
        );
        setIsDetailExtracted(extractedData.length > 0 ? true : false);
        setIsFetching(false);
      } else {
        // Data not available, retry after 5 seconds
        console.log("No data yet. Retrying after 5 seconds...");
        setTimeout(() => pollExtractedDetails(referenceId), 5000);
      }
    } catch (error) {
      console.error("Error fetching extracted details:", error);
      // Retry even if there's an error
      console.log("Retrying after 5 seconds due to an error...");
      setTimeout(() => pollExtractedDetails(referenceId), 5000);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "820px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="relative font-hankengrotesk scrollbar !py-[52px] lg:!px-[0px]">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          >
            <img src={CrossIcon} alt="crossIcon" />
          </div>
          <div className="rounded-md">
            <div
              className={`max-w-[530px] m-auto font-semibold text-[28px] leading-9 text-center ${
                mode === "edit" ? "mb-6" : ""
              }`}
            >
              {step === 0
                ? "New Job"
                : step === 1
                ? "Upload Candidates"
                : "Call Details"}
            </div>

            {mode === "add" && (
              <div className="max-w-[530px] m-auto mt-6 mb-8">
                <Stepper selectedStep={step} steps={jobCreationSteps} />
              </div>
            )}
            <div className="h-full">
              <div className="max-w-[530px] m-auto xl:mb-[52px] lg:mb-9 md:mb-7 mb-4 flex flex-col">
                {step === 0
                  ? renderStep1Content()
                  : step === 1
                  ? renderStep2Content()
                  : step === 2
                  ? renderStep3Content()
                  : null}
              </div>
            </div>

            <div className="max-w-[530px] m-auto w-full flex justify-between items-center gap-3 sm:gap-0">
              <button
                className={`btn h-[50px] bg-white flex items-center border-custom-primary border-[1px] text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  ${
                  step === 0 ? "opacity-40 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (step > 0) {
                    setStep(step - 1);
                    setValidationErrors({});
                  }
                }}
                disabled={step === 0}
              >
                {mode === "edit" ? "Cancel" : "Back"}
              </button>
              <button
                className="btn h-[50px] flex items-center bg-custom-primary text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                onClick={() =>
                  mode === "edit"
                    ? handleEditSubmit()
                    : step === 2
                    ? handleSubmit()
                    : handleNextStep()
                }
                disabled={submitLoading}
              >
                {submitLoading
                  ? "Submitting..."
                  : step === 2 || mode === "edit"
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewJob;
