import _ from "lodash";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { Dialog, DialogContent, Slide, Switch } from "@mui/material";
import { ArrowDown, CrossIcon, DeleteIconRed, WarningInfoIcon } from "assets";
import FileUpload from "component/FileUpload";
import { createCandidates } from "services/Job";
import InfoDialog from "./InfoDialog";
import {
  formatNumberInProperForm,
  generateRandomId,
  handleDownloadSampleCsv,
  validatePhoneNumber,
} from "utils/common";
import MultipleFileUpload from "component/UploadMultiplePdfFiles";
import CallInfo from "dialogs/callInfoDialog";
import {
  extractPdfDetail,
  fetchExtractedDetailByReferenceId,
} from "services/Company";
import PhoneNumberInput from "component/PhoneNumberField";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadCandidates({
  open,
  setOpen,
  jobId,
  handleCallBack,
}) {
  const [loading, setLoading] = useState(false);
  const [uploadedCandidates, setUploadedCandidates] = useState([]);
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });
  const [manualUploadDetail, setManualUploadDetail] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "+44",
  });
  const [validationErrors, setValidationErrors] = useState({
    fileInfo: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [infoDialog, setInfoDialog] = useState(false);
  const [duplicates, setDuplicates] = useState(0);

  // new flow chanegs
  const [uploadMode, setUploadMode] = useState("cv");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [manualUploadValues, setManualUploadValues] = useState([
    { firstName: "", lastName: "", phoneNumber: "+44", id: generateRandomId() },
  ]);
  const [manualAccordian, setManualAccordian] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState([
    // manual upload fields value error
    { firstName: "", lastName: "", phoneNumber: "" },
  ]);
  const [isFetching, setIsFetching] = useState(false);
  const [isExtractInfoDialog, setIsExtractInfoDialog] = useState(false);
  const [isDetailExtracted, setIsDetailExtracted] = useState(false);

  const errorMsg = {
    fileInfo: "CSV or Excel file must be uploaded",
    firstName: "First name is required",
    lastName: "Last name is required",
    phoneNumber: "Phone number is required",
    cvFiles: "Cv files is required",
    extractDetail: "Extract detail is required",
    manualDetail: "Please fill up required detail",
  };
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const validateSubmit = () => {
    const errors = {};
    if (fileInfo.name) {
      return errors;
    }

    if (uploadMode === "cv") {
      if (!isDetailExtracted && uploadedFiles.length === 0) {
        errors.cvError = errorMsg.cvFiles;
      } else if (!isDetailExtracted && uploadedFiles.length > 0) {
        errors.extractDetailError = errorMsg.extractDetail;
      } else if (isDetailExtracted && !validateAllFields()) {
        errors.manualDetail = errorMsg.manualDetail;
      }
    } else {
      if (!fileInfo.name && manualAccordian) {
        if (!validateAllFields()) {
          errors.manualDetail = errorMsg.manualDetail;
        }
      } else if (!fileInfo.name) {
        errors.fileInfo = errorMsg.fileInfo;
      }
    }

    return errors;
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        cvError: "",
        extractDetailError: "",
      }));
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (open) {
      resetState();
      setUploadMode("cv");
      setManualUploadValues([
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);
    }
  }, [open]);

  const resetState = () => {
    setLoading(false);
    setUploadedFiles([]);
    setUploadedCandidates([]);
    setFileInfo({ name: "", size: 0 });
    setValidationErrors({
      fileInfo: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    });
    setFileList([]);
    setErrors([{ firstName: "", lastName: "", phoneNumber: "" }]);
    setManualAccordian(false);
    setIsDetailExtracted(false);
  };

  const handleSubmit = async () => {
    let errors = validateSubmit();

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      const uploadedData =
        fileInfo?.name && uploadedCandidates.length > 0
          ? uploadedCandidates
          : manualUploadValues.map((m) => ({
              firstName: m.firstName,
              lastName: m.lastName,
              phoneNumber: m.phoneNumber,
            }));
      if (uploadedData.length > 0) {
        console.log("uploadedData", uploadedData);
        setLoading(true);
        const result = await createCandidates(jobId, uploadedData);
        setLoading(false);
        setOpen(false);
        handleCallBack();
        if (result?.data?.result?.skipped) {
          setInfoDialog(true);
          // isManualUpload && setManualCandidateAlreadyUpload(true);
          setDuplicates(result?.data?.result?.skipped);
        }
        //  else if (result?.data?.result?.created && isManualUpload) {
        //   setManuallyUploadedList([
        //     ...manuallyUploadedList,
        //     manualUploadDetail,
        //   ]);
        //   setManualUploadDetail({
        //     firstName: "",
        //     lastName: "",
        //     phoneNumber: "+44",
        //   });
        // }
      }
    }
  };

  const validateField = (name, value) => {
    let errMsg = "";

    if (!(value && value.trim())) {
      errMsg = errorMsg[name];
    } else if (name === "phoneNumber") {
      errMsg = validatePhoneNumber(value);
    }

    return errMsg;
  };

  const handleFieldChange = (index, name, value) => {
    const updatedFields = [...manualUploadValues];

    updatedFields[index][name] = value;

    setManualUploadValues(updatedFields);

    // Dynamically validate the field
    const updatedErrors = [...errors];
    updatedErrors[index][name] = validateField(
      name,
      updatedFields[index][name]
    );
    setErrors(updatedErrors);
  };

  const handleFieldBlur = (index, name) => {
    const updatedErrors = [...errors];
    updatedErrors[index][name] = validateField(
      name,
      manualUploadValues[index][name]
    );
    setErrors(updatedErrors);
  };

  const validateAllFields = () => {
    const nonEmptyValues = manualUploadValues.filter(
      (field) =>
        field?.firstName?.trim() ||
        field?.lastName?.trim() ||
        field?.phoneNumber?.trim()
    );

    if (nonEmptyValues.length === 0) {
      setManualUploadValues([
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);

      const newErrors = [
        { firstName: "", lastName: "", phoneNumber: "+44" },
      ].map((field) => ({
        firstName: validateField("firstName", field.firstName),
        lastName: validateField("lastName", field.lastName),
        phoneNumber: validateField("phoneNumber", field.phoneNumber),
      }));

      setErrors(newErrors);
      return false;
    }

    setManualUploadValues(nonEmptyValues);

    const newErrors = nonEmptyValues.map((field) => ({
      firstName: validateField("firstName", field.firstName),
      lastName: validateField("lastName", field.lastName),
      phoneNumber: validateField("phoneNumber", field.phoneNumber),
    }));

    setErrors(newErrors);

    return newErrors.every(
      (error) => !error.firstName && !error.lastName && !error.phoneNumber
    );
  };

  const handleAddFields = () => {
    // clear if anything uploaded through file
    setUploadedCandidates([]);
    setFileInfo({ name: "", size: 0 });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      fileInfo: "", // Clear file error
    }));

    if (validateAllFields()) {
      setManualUploadValues([
        ...manualUploadValues,
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);
      setErrors([...errors, { firstName: "", lastName: "", phoneNumber: "" }]);
    }
    const element = document.getElementById("add-field-button");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleRemoveField = (index, isManualList = false) => {
    let manualUploadCopy = [...manualUploadValues];
    let errorsCopy = [...errors];
    manualUploadCopy.splice(index, 1);
    errorsCopy.splice(index, 1);
    setManualUploadValues(manualUploadCopy);
    setErrors(errorsCopy);
    if (manualUploadCopy.length === 0) {
      isManualList ? setManualAccordian(false) : setIsDetailExtracted(false);
    }
  };

  const renderInputField = (index, name, placeholder, label, type = "text") => (
    <div
      className={`w-full flex flex-col gap-1 flex-1 ${
        name === "phoneNumber" ? "common-manual-upload common-tel-phone" : ""
      } ${errors[index]?.[name] ? "error-tel-phone" : ""}`}
    >
      <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px]">
        {label}
      </label>
      {name === "phoneNumber" ? (
        <PhoneNumberInput
          handleFieldChange={(value) => handleFieldChange(index, name, value)}
          value={manualUploadValues[index][name]}
          placeholder="Ex +44 7700 900000"
        />
      ) : (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={manualUploadValues[index][name]}
          onChange={(e) => handleFieldChange(index, name, e.target.value)}
          onBlur={() => handleFieldBlur(index, name)}
          className={`input text-gray-700 bg-white border ${
            errors[index]?.[name] ? "border-red-500" : "border-gray-300"
          } rounded-lg py-4 px-4 block w-full outline-none`}
        />
      )}
      {errors[index]?.[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[index][name]}
        </div>
      )}
    </div>
  );

  const handleUploadingModeChange = (e) => {
    setUploadMode(e.target.checked ? "csv" : "cv");
    setManualUploadValues(
      e.target.checked
        ? [
            {
              firstName: "",
              lastName: "",
              phoneNumber: "+44",
              id: generateRandomId(),
            },
          ]
        : []
    );
    resetState();
  };

  const handleAccordionClick = () => {
    setManualAccordian(!manualAccordian);
    if (manualUploadValues.length === 0) {
      setManualUploadValues([
        {
          firstName: "",
          lastName: "",
          phoneNumber: "+44",
          id: generateRandomId(),
        },
      ]);
    }
    if (errors.length === 0) {
      setErrors([{ firstName: "", lastName: "", phoneNumber: "" }]);
    }
    setValidationErrors((prev) => ({
      ...prev,
      fileInfo: "",
    }));
    // Add smooth scroll after state update
    if (!manualAccordian) {
      setTimeout(() => {
        const scrollToElement = document.getElementById("add-field-button");
        if (scrollToElement) {
          scrollToElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  const handleExtractDetails = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    const hidePopup = localStorage.getItem("hideExtractPopup");
    if (!hidePopup || hidePopup !== "true") {
      setIsExtractInfoDialog(true);
    }
    try {
      setIsFetching(true);
      setValidationErrors({});
      const response = await extractPdfDetail(formData);

      if (response.data.data.reference_id) {
        pollExtractedDetails(response.data.data.reference_id);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsFetching(false);
    }
  };

  const pollExtractedDetails = async (referenceId) => {
    try {
      const data = await fetchExtractedDetailByReferenceId(referenceId);
      if (data && data.length > 0) {
        // Data fetched successfully, stop polling
        const extractedData = data.map((details) => {
          const parsedDetail = details.extractedDetails
            ? JSON.parse(details.extractedDetails)
            : {};
          return {
            id: generateRandomId(),
            firstName: parsedDetail.first_name,
            lastName: parsedDetail.last_name,
            phoneNumber: parsedDetail.phone_number || "+44",
          };
        });

        console.log("Extracted Data:", extractedData);
        const dataWithFormattedPhoneNumber =
          formatNumberInProperForm(extractedData);
        console.log("Formatted Data:", dataWithFormattedPhoneNumber);
        setManualUploadValues(dataWithFormattedPhoneNumber);
        setErrors(
          extractedData.map((m) => ({
            firstName: "",
            lastName: "",
            phoneNumber: "",
          }))
        );
        setIsDetailExtracted(extractedData.length > 0 ? true : false);
        setIsFetching(false);
      } else {
        // Data not available, retry after 5 seconds
        console.log("No data yet. Retrying after 5 seconds...");
        setTimeout(() => pollExtractedDetails(referenceId), 5000);
      }
    } catch (error) {
      console.error("Error fetching extracted details:", error);
      // Retry even if there's an error
      console.log("Retrying after 5 seconds due to an error...");
      setTimeout(() => pollExtractedDetails(referenceId), 5000);
    }
  };

  return (
    <>
      {infoDialog && duplicates && (
        <InfoDialog
          open={infoDialog}
          setOpen={setInfoDialog}
          infoDetail={{
            heading: `We have Detected and Removed ${duplicates} Duplicates`,
            innerContent: "",
          }}
          handleSubmit={() => {}}
          icon={WarningInfoIcon}
        />
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "820px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent
          className={`relative font-hankengrotesk scrollbar !py-[52px] lg:!px-0px]`}
        >
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          >
            <img src={CrossIcon} alt="crossIcon" />
          </div>
          <div className={`rounded-md max-w-[510px] m-auto`}>
            <div className="flex justify-between items-center max-w-[510px] mx-auto">
              <div className="font-semibold text-[28px] leading-9 text-center">
                Upload Candidates
              </div>

              <div className="flex items-center gap-1 justify-end">
                <Switch
                  checked={uploadMode === "csv"}
                  onChange={(e) => handleUploadingModeChange(e)}
                  color="primary"
                />
                <span className="text-sm font-medium text-gray-700">
                  CSV Upload
                </span>
              </div>
            </div>
            <div className="mt-8 xl:mb-[52px] lg:mb-9 md:mb-7 mb-4 ">
              <div className="flex flex-col gap-5">
                {uploadMode === "csv" ? (
                  <>
                    <div className="flex flex-col gap-1">
                      <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
                        Upload CSV
                      </h2>
                      <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
                        Please upload a CSV file with the candidates names and
                        contact numbers.{" "}
                        <span
                          className="text-blue-500 cursor-pointer"
                          onClick={handleDownloadSampleCsv}
                        >
                          Click here
                        </span>{" "}
                        to see a template of what it should look like.
                      </h3>
                    </div>
                    <div className="flex flex-col gap-3">
                      <FileUpload
                        fileInfo={fileInfo}
                        setFileInfo={(file) => {
                          setFileInfo(file);
                          // Clear file validation error if file is uploaded
                          if (file && file.name) {
                            setValidationErrors((prevErrors) => ({
                              ...prevErrors,
                              fileInfo: null, // Clear file error
                            }));
                            setManualUploadValues([
                              {
                                firstName: "",
                                lastName: "",
                                phoneNumber: "+44",
                                id: generateRandomId(),
                              },
                            ]);
                            setErrors([
                              { firstName: "", lastName: "", phoneNumber: "" },
                            ]);
                            setManualAccordian(false);
                          }
                        }}
                        setUploadedCandidates={setUploadedCandidates}
                      />
                      {validationErrors.fileInfo && (
                        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                          {validationErrors.fileInfo}
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <Fragment>
                    <div className="flex flex-col gap-1">
                      <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
                        Upload CVs (PDF)
                      </h2>
                      <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
                        Upload multiple CVs in PDF format to extract candidate
                        details.
                      </h3>
                    </div>
                    <div className="flex flex-col gap-2">
                      <MultipleFileUpload
                        setUploadedFiles={setUploadedFiles}
                        setFileList={setFileList}
                        fileList={fileList}
                        addMoreBtnDisabled={isFetching}
                      />
                      {validationErrors.cvError && (
                        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                          {validationErrors.cvError}
                        </span>
                      )}
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={handleExtractDetails}
                          className="h-[40px] px-6 bg-custom-primary text-white rounded-lg disabled:opacity-75"
                          disabled={isFetching || uploadedFiles.length === 0}
                        >
                          {isExtractInfoDialog && (
                            <CallInfo
                              HideKeyStorage={"hideExtractPopup"}
                              open={isExtractInfoDialog}
                              setOpen={setIsExtractInfoDialog}
                              handleSubmit={() => {}}
                              infoDetail={{
                                heading: "PDF Extraction in Progress",
                                innerContent:
                                  "Your PDF is currently being processed. This may take 1-2 minutes. Please wait while we extract the necessary information.",
                              }}
                            />
                          )}
                          {isFetching ? "Extracting..." : "Extract Details"}
                        </button>
                      </div>
                    </div>
                  </Fragment>
                )}
                {uploadMode === "csv" ? (
                  <div>
                    <div className="flex justify-center items-center text-[#1f1f1f66] relative before:content-[''] before:absolute before:top-1/2 before:left-0 before:h-[1px] before:w-[calc(50%_-_20px)] before:bg-[#E5E5E5] after:content-[''] after:absolute after:top-1/2 after:right-0 after:h-[1px] after:w-[calc(50%_-_20px)] after:bg-[#E5E5E5]">
                      OR
                    </div>
                    <div className="border-b-[1px] border-[#EFEFEF] py-3">
                      <div
                        className="cursor-pointer flex justify-between items-center text-[16px] font-medium text-[#1F1F1F] leading-[22px]"
                        onClick={handleAccordionClick}
                      >
                        Upload Manually
                        {manualAccordian ? (
                          <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
                            <img
                              src={ArrowDown}
                              alt="ArrowUp"
                              className="rotate-180"
                            />
                          </div>
                        ) : (
                          <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
                            <img src={ArrowDown} alt="ArrowDown" />
                          </div>
                        )}
                      </div>

                      <div
                        className={`mt-3 transition-all duration-700 ease-in-out overflow-hidden ${
                          manualAccordian
                            ? "max-h-full opacity-100"
                            : "max-h-0 opacity-0"
                        }`}
                      >
                        <div className="flex flex-col gap-4">
                          {manualUploadValues.map((field, index) => (
                            <div
                              key={_.get(field, "id", index)}
                              className="w-full flex items-center gap-0"
                            >
                              <div className="flex w-full items-start gap-2">
                                {renderInputField(
                                  index,
                                  "firstName",
                                  "Ex John",
                                  "First Name"
                                )}
                                {renderInputField(
                                  index,
                                  "lastName",
                                  "Ex Doe",
                                  "Last Name"
                                )}
                                {renderInputField(
                                  index,
                                  "phoneNumber",
                                  "Ex +44 24 3421 331",
                                  "Phone Number"
                                )}
                              </div>
                              <div
                                className={`min-w-7 min-h-7 mt-[29px] flex justify-center items-center cursor-pointer ${
                                  _.get(errors, `[${index}].firstName`, "") ||
                                  _.get(errors, `[${index}].phoneNumber`, "") ||
                                  _.get(errors, `[${index}].lastName`, "")
                                    ? "mb-6"
                                    : ""
                                }`}
                                onClick={() => handleRemoveField(index, true)}
                              >
                                <img
                                  src={DeleteIconRed}
                                  alt="Delete Icon"
                                  width={28}
                                  height={28}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex justify-end mt-3 w-[calc(100%_-_28px)]">
                          <button
                            type="button"
                            id="add-field-button"
                            onClick={handleAddFields}
                            className="h-[36px] w-[72px] flex justify-center items-center bg-custom-primary text-white rounded-lg py-[10px] px-6"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    {validationErrors.manualDetail && (
                      <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                        {validationErrors.fileInfo}
                      </span>
                    )}
                  </div>
                ) : (
                  /* <div className="w-full flex justify-between items-center gap-3 sm:gap-0">
                  <button
                    className={`btn h-[50px] bg-white flex items-center border-custom-primary border text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg`}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn h-[50px] flex items-center bg-custom-primary text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    {loading ? "Uploading..." : "Upload"}
                  </button>
                </div> */
                  uploadMode === "cv" &&
                  isDetailExtracted && (
                    <Fragment>
                      <div className="mt-3 transition-all duration-700 ease-in-out overflow-hidden max-h-full opacity-100">
                        <div className="flex flex-col gap-4">
                          {manualUploadValues.map((field, index) => (
                            <div
                              key={_.get(field, "id", index)}
                              className="w-full flex items-center gap-4"
                            >
                              <div class="flex w-full items-start gap-2">
                                {renderInputField(
                                  index,
                                  "firstName",
                                  "Ex John",
                                  "First Name"
                                )}
                                {renderInputField(
                                  index,
                                  "lastName",
                                  "Ex Doe",
                                  "Last Name"
                                )}
                                {renderInputField(
                                  index,
                                  "phoneNumber",
                                  "Ex +44 24 3421 331",
                                  "Phone Number"
                                )}
                              </div>
                              <div
                                className={`min-w-7 min-h-7 mt-[29px] flex justify-center items-center cursor-pointer ${
                                  _.get(errors, `[${index}].firstName`, "") ||
                                  _.get(errors, `[${index}].phoneNumber`, "") ||
                                  _.get(errors, `[${index}].lastName`, "")
                                    ? "mb-6"
                                    : ""
                                }`}
                                onClick={() => handleRemoveField(index)}
                              >
                                <img
                                  src={DeleteIconRed}
                                  alt="Delete Icon"
                                  width={28}
                                  height={28}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {validationErrors.manualDetail && (
                        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                          {validationErrors.fileInfo}
                        </span>
                      )}
                    </Fragment>
                  )
                )}
                {validationErrors.extractDetailError && (
                  <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                    {validationErrors.extractDetailError}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full flex justify-between items-center gap-3 sm:gap-0">
              <button
                className={`btn h-[50px] bg-white flex items-center border-custom-primary border text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg`}
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn h-[50px] flex items-center bg-custom-primary text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
