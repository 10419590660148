import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  ArrowBackIcon,
  ArrowRight,
  CompletedGreen,
  EditIcon,
  FilterIcon,
  HorizontalDots,
  MapPinIcon,
  NoteIcon,
  PhoneMissedGrayIcon,
  PhoneMissedSecondaryIcon,
  SearchBlue,
  TranscriptionIcon,
  TrashIcon,
  UserUploadPrimary,
  WarningInfoIcon,
  WarningInfoRedIcon,
} from "assets";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TabsComponent from "component/Tabs";
import CustomizedTable, {
  StyledTableCell,
  StyledTableRow,
} from "component/Table";
import {
  callStatusDetail,
  categoryChipColor,
  debounce,
  getListTableColumns,
  renderCategoryValue,
  validatePhoneNumber,
} from "utils/common";
import MenuPopper from "component/MenuPopper";
import TranscriptionDetail from "dialogs/TranscriptionDetail";
import DeleteAccount from "dialogs/deleteAccount";
import CandidateEmptyScreen from "./candidateEmptyScreen";
import CandidateFilters from "component/CandidateFilter";
import CustomTooltip from "component/Tooltip";
import CallInfo from "dialogs/callInfoDialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "component/Loader";
import CompanyCandidateAIChat from "pages/CompanyCandidateAIChat";
import SubscriptionPlanDialog from "dialogs/subscriptionDialog";
import AddCallsDialog from "dialogs/addCallsDialog";
import { Paths } from "routes";
import CompanyListDetailSkelaton from "./companyListDetailSkeleton";
import InfoDialoadWithHeader from "dialogs/infoDialogWithHeader";
import {
  cancelAllCalls,
  cancelSingleCall,
  deleteCompany,
  dialAllCalls,
  executeAICall,
  getCandidateCompanies,
  getCompaniesCandidates,
  getCompanyCandidateById,
  updateCandidateStatus,
  updateCompany,
  updateCompanyCandidate,
  updateCompanyPhoneNumber,
} from "services/Company";
import UploadCompanies from "dialogs/UploadCompanies";
import CandidateList from "./candidateList";
import CompanySummaryDetail from "./companySummaryDetail";
import NewCompanyCandidateDialog from "dialogs/NewCompanyCandidate";
import InfoDialogV2 from "dialogs/InfoDialogV2";
import CandidateListMenuPopper from "component/CandidateListMenu";
import CompanyCandidateCv from "dialogs/companyCandidateCvDialog";
import PhoneNumberInput from "component/PhoneNumberField";
import { useNotification } from "contexts/notificationContext";

const initialInfoDialog = {
  open: false,
  infoDetail: null,
  icon: "",
  id: "",
};

export default function JobDetail({ auth }) {
  const { candidateId } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const currentRouteSubPage = currentPath?.split("/")?.[2]; // ai-chat

  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [mainLoader, setMainLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedCandidateDetail, setSelectedCandidateDetail] = useState(null);
  const [selectedCandidates] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const categoryRef = useRef("");
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(currentPage);
  const [tabValue, setTabValue] = useState(
    currentRouteSubPage === "ai-chat" ? 1 : 0
  );
  const [uploadCompany, setUploadCompany] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef(searchValue);
  const [menuPopperRef, setMenuPopperRef] = useState(null);
  const [transcriptionDetail, setTranscriptionDetail] = useState(false);
  const [deleteCompanyPopup, setDeleteCompanyPopup] = useState(false);
  const [removeFromStrongCompany, setRemoveFromStrongCompany] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isCompanyCallSummary, setIsCompanyCallSummary] = useState(false);
  const [isFromSingleCompany, setIsFromSingleCompany] = useState(false);
  const [isCallInfoDialog, setIsCallInfoDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [infoDialogDetail, setInfoDialogDetail] = useState(initialInfoDialog);
  const [isAddCreditsPopup, setIsAddCreditsPopup] = useState(false);
  const [moreVertMenuAnchorEl, setMoreVertMenuAnchorEl] = useState(null);
  const [moreVertAction, setMoreVertAction] = useState(""); // edit_candidate_details || edit_call || archive_job
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [appliedFilters, setAppliedFilter] = useState({
    category: "all",
  });
  const [candidateListDropdownMenu, setCandidateListDropdownMenu] =
    useState(false);
  const [companyCandidatesList, setCompanyCandidatesList] = useState(null);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);
  const [addCreditsPopup, setAddCreditsPopup] = useState(false);
  const [btnLoading, setBtnLoading] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [savingPhoneNumber, setSavingPhoneNumber] = useState(false);

  const fetchCompanies = async (
    offset = 10 * (currentPage - 1),
    value = searchValue,
    limit = 10,
    category = categoryRef.current,
    silent = false
  ) => {
    try {
      // If JobDetail is not available or call from settimeout, fetch both JobDetail and candidates in parallel
      if (!selectedCandidateDetail || silent) {
        if (!silent) setMainLoader(true);
        const [candidateDetail, result] = await Promise.all([
          getCompanyCandidateById(candidateId),
          getCandidateCompanies(candidateId, value, limit, offset, category),
        ]);

        if (!candidateDetail) {
          navigate(Paths.JobOverview);
          return;
        }

        setSelectedCandidateDetail(candidateDetail);
        setCompaniesList(result?.data?.result?.candidates);
        setTotalCompanies(Number(result?.data?.result?.totalCount || 0));
      } else {
        if (!silent) setLoading(true);
        const result = await getCandidateCompanies(
          selectedCandidateDetail?.id,
          value,
          limit,
          offset,
          category
        );
        setCompaniesList(result?.data?.result?.candidates);
        setTotalCompanies(Number(result?.data?.result?.totalCount || 0));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setMainLoader(false);
      setLoading(false);
    }
  };

  const fetchCompaniesCandidateList = async () => {
    if (!companyCandidatesList) {
      const result = await getCompaniesCandidates("");
      setCompanyCandidatesList(result?.data?.result);
    }
  };

  const triggerAICall = async (candidateId) => {
    if (
      _.get(auth, "userData.Subscription[0].status", "") !== "active" &&
      _.get(auth, "userData.Subscription[0].status", "") !== "trialing"
    ) {
      setSubscriptionPopup(true);
    } else {
      setLoading(true);
      try {
        await executeAICall(selectedCandidateDetail?.id, candidateId);
        const hidePopup = localStorage.getItem("hideCallInfoPopup");
        if (!hidePopup || hidePopup !== "true") {
          setIsCallInfoDialog(true);
        }
      } catch (error) {
        setIsAddCreditsPopup(true);
        console.error("Error triggering AI call:", error);
      }
      await fetchCompanies();
    }
  };

  useEffect(() => {
    fetchCompanies();
    // this api call in every 5sec even we're in ai chat tab need to restrict
    const intervalId = setInterval(() => {
      fetchCompanies(
        10 * (currentPageRef.current - 1),
        searchRef.current,
        undefined,
        categoryRef.current,
        true
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [candidateId]);

  useEffect(() => {
    if (selectedCompany && companiesList?.length > 0) {
      const updatedSelectedCandidate = companiesList?.find(
        (candidate) => candidate?.id === selectedCompany?.id
      );
      updatedSelectedCandidate && setSelectedCompany(updatedSelectedCandidate);
    }
  }, [companiesList]);

  const handleBackClick = () => {
    if (isCompanyCallSummary) {
      setIsCompanyCallSummary(false);
    } else {
      navigate(Paths.CompanyOverview);
    }
  };

  // const handleSelect = (event) => {
  //   const { value } = event.target;

  //   setCandidateType(value);
  //   categoryRef.current = value;
  //   fetchCompanies(undefined, undefined, undefined, value);
  // };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    currentPageRef.current = newPage;
    fetchCompanies(10 * (newPage - 1));
  };

  const handleCompanyClick = (row) => {
    // const candidateSummary = row?.summary
    //   ? JSON.parse(row?.summary || {})
    //   : null;

    setIsFromSingleCompany(true);
    setIsCompanyCallSummary(true);
    setSelectedCompany(row);
  };

  const debouncedSearch = debounce(fetchCompanies, 1000);

  const handleSearchCompany = (value) => {
    setSearchValue(value);
    setCurrentPage(1);
    searchRef.current = value;
    currentPageRef.current = 1;
    debouncedSearch(0, value);
  };

  const handleMenuClick = (id) => {
    setMenuPopperRef(null);
    if (id === "view_transcript") {
      setTranscriptionDetail(true);
    } else if (id === "remove") {
      setDeleteCompanyPopup(true);
    } else if (id === "view_notes") {
      setIsFromSingleCompany(false);
      setIsCompanyCallSummary(true);
      // const candidateSummary = selectedCompany?.summary
      //   ? JSON.parse(selectedCompany?.summary || {})
      //   : null;
      // candidateSummary &&
      //   candidateSummary?.summary &&
      //   setCandidateCallSummary(candidateSummary?.summary);
    } else if (id === "remove_from_strong_candidate") {
      setRemoveFromStrongCompany(true);
      setDeleteCompanyPopup(true);
    }
  };

  const handleMoreVertMenuClick = async (id) => {
    setMoreVertMenuAnchorEl(null);
    if (
      id === "view_cv" ||
      id === "edit_candidate_details" ||
      id === "edit_call"
    ) {
      setMoreVertAction(id);
    } else if (id === "remove") {
      setConfirmDelete(true);
    }
  };

  const handleDeleteCompany = async () => {
    if (removeFromStrongCompany) {
      setRemoveFromStrongCompany(false);
      await updateCompany(selectedCandidateDetail?.id, selectedCompany?.id, {
        category: "interested",
      });
    } else {
      await deleteCompany(selectedCandidateDetail?.id, selectedCompany?.id);
      setIsCompanyCallSummary(false);
      setSelectedCompany(null);
    }
    fetchCompanies();
  };

  const handleDeleteCandidate = async () => {
    const response = await updateCandidateStatus(selectedCandidateDetail?.id, {
      status: "deleted",
    });
    if (response.data) {
      navigate(Paths.CompanyOverview);
    }
  };

  const handleSaveFilter = (appliedFilters) => {
    setAppliedFilter(appliedFilters);
    setFilterAnchorEl(null);
    setCurrentPage(1);
    categoryRef.current = appliedFilters?.category;
    fetchCompanies(0, undefined, undefined, appliedFilters?.category);
  };

  const tableColumns = useMemo(() => {
    return getListTableColumns("candidate");
  }, []);

  const handleDialAllCall = async () => {
    try {
      setBtnLoading("dial_all_call");
      await dialAllCalls(candidateId);
      setBtnLoading("");
      fetchCompanies();
    } catch (error) {
      setBtnLoading("");
      if (
        error?.response &&
        error?.response?.data?.code === "plan_limit_exceeded"
      ) {
        setInfoDialogDetail({
          open: true,
          icon: WarningInfoIcon,
          infoDetail: {
            heading: error?.response?.data?.title,
          },
          id: error?.response?.data?.code,
        });
      } else if (
        error?.response &&
        error?.response?.data?.code === "credit_limit_exceeded"
      ) {
        setIsAddCreditsPopup(true);
      }
    }
  };

  const handleStopAllCalls = async () => {
    try {
      await cancelAllCalls(candidateId);
      fetchCompanies();
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleCancelSingleCall = async (candidateId) => {
    try {
      await cancelSingleCall(candidateId, candidateId);
      fetchCompanies();
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateCandidateDetail = async () => {
    const candidateDetail = await getCompanyCandidateById(candidateId);
    setSelectedCandidateDetail(candidateDetail);
  };

  const handleUpdateCandidate = async (candidateData) => {
    const result = await updateCompanyCandidate(
      selectedCandidateDetail?.id,
      candidateData
    );
    setMoreVertAction("");
    if (result?.data?.result) {
      setInfoDialogDetail({
        icon: CompletedGreen,
        infoDetail: {
          heading: `Changes Saved!.`,
          innerContent: "",
        },
        open: true,
      });
      await updateCandidateDetail();
    }
  };

  const validateCompanyPhoneNumber = (value) => {
    let errMsg = validatePhoneNumber(value);
    setEditedPhoneNumber(value);
    setPhoneNumberError(errMsg);
    return errMsg;
  };

  const handlePhoneNumberChange = (value) => {
    validateCompanyPhoneNumber(value);
  };

  const handleEditClick = (row) => {
    setEditingRow(row.id);
    setEditedPhoneNumber(row.phoneNumber);
    validateCompanyPhoneNumber(row.phoneNumber);
  };

  const handleCancelEdit = () => {
    setEditingRow(null);
    setEditedPhoneNumber("");
    setPhoneNumberError("");
  };

  const handleSavePhoneNumber = async (row) => {
    const errMsg = validateCompanyPhoneNumber(editedPhoneNumber);
    if (errMsg) {
      return;
    }
    setSavingPhoneNumber(true);

    try {
      await updateCompanyPhoneNumber(candidateId, row.id, {
        phoneNumber: editedPhoneNumber,
      });
      await fetchCompanies(
        10 * (currentPage - 1),
        searchValue,
        10,
        categoryRef.current,
        true
      );
      setEditingRow(null);
    } catch (error) {
      console.error("Failed to update phone number:", error);
      showNotification(
        _.get(error, "response.data.code", "Unauthorized Error"),
        "error"
      );
    } finally {
      setSavingPhoneNumber(false);
    }
  };

  return (
    <Fragment>
      {subscriptionPopup && (
        <SubscriptionPlanDialog
          open={subscriptionPopup}
          setOpen={setSubscriptionPopup}
        />
      )}
      {addCreditsPopup && (
        <AddCallsDialog open={addCreditsPopup} setOpen={setAddCreditsPopup} />
      )}
      {candidateListDropdownMenu && (
        <CandidateListMenuPopper
          anchorRef={candidateListDropdownMenu}
          handleClose={() => {
            setCandidateListDropdownMenu(null);
          }}
          candidateList={companyCandidatesList}
          handleCandidateClick={(candidateId) => {
            setSelectedCandidateDetail(null);
            setCandidateListDropdownMenu(null);
            navigate(`/candidate/${candidateId}`);
          }}
        />
      )}
      {infoDialogDetail.open && (
        <InfoDialogV2
          open={true}
          setOpen={(open) => setInfoDialogDetail(initialInfoDialog)}
          infoDetail={infoDialogDetail.infoDetail}
          handleSubmit={() => {
            if (infoDialogDetail.id === "plan_limit_exceeded") {
              setSubscriptionPopup(true);
            }
          }}
          icon={infoDialogDetail.icon}
        />
      )}
      <UploadCompanies
        open={uploadCompany}
        setOpen={setUploadCompany}
        candidateId={selectedCandidateDetail?.id}
        handleCallBack={() => fetchCompanies()}
      />
      {menuPopperRef && (
        <MenuPopper
          open={Boolean(menuPopperRef)}
          anchorRef={menuPopperRef}
          handleClose={() => {
            setMenuPopperRef(null);
            setSelectedCompany(null);
          }}
          menuItems={[
            ...(!(selectedCompany && !selectedCompany?.recordingDownloaded)
              ? [
                  {
                    id: "view_transcript",
                    label: "View Transcript",
                    icon: TranscriptionIcon,
                    disabled:
                      selectedCompany && !selectedCompany?.recordingDownloaded,
                  },
                ]
              : []),
            {
              id: "view_notes",
              label: "View Notes",
              icon: NoteIcon,
            },
            // { id: "remove", label: "Remove", icon: TrashIcon },
            ...(selectedCompany?.callStatus !== "in-progress" &&
            selectedCompany?.callStatus !== "ringing" &&
            selectedCompany?.callStatus !== "triggered" &&
            selectedCompany?.callStatus !== "triggering"
              ? [
                  {
                    id: "remove",
                    label: "Remove",
                    icon: TrashIcon,
                  },
                ]
              : []),
            ...(selectedCompany?.category === "strong_candidate"
              ? [
                  {
                    id: "remove_from_strong_candidate",
                    label: "Remove from strong candidate",
                    icon: TrashIcon,
                  },
                ]
              : []),
          ]}
          handleMenuClick={(id) => handleMenuClick(id)}
        />
      )}
      {(moreVertAction === "edit_candidate_details" ||
        moreVertAction === "edit_call") && (
        <NewCompanyCandidateDialog
          open={true}
          setOpen={() => setMoreVertAction("")}
          handleCreateCandidate={handleUpdateCandidate}
          mode="edit"
          candidateData={selectedCandidateDetail}
          editState={moreVertAction}
        />
      )}
      {moreVertAction === "view_cv" && (
        <CompanyCandidateCv
          open={true}
          setOpen={() => setMoreVertAction("")}
          cvPath={selectedCandidateDetail.cvPath}
        />
      )}
      {isCallInfoDialog && (
        <CallInfo
          HideKeyStorage={"hideCallInfoPopup"}
          open={isCallInfoDialog}
          setOpen={setIsCallInfoDialog}
          handleSubmit={() => {}}
          infoDetail={{
            heading: "Calls in Queue",
            innerContent:
              "Your call requests have been made. Your companies will start to receive calls in 1 minute. You can see status to track the progress of each call.",
          }}
        />
      )}
      {/* Need to update description */}
      {isAddCreditsPopup && (
        <InfoDialoadWithHeader
          open={isAddCreditsPopup}
          setOpen={setIsAddCreditsPopup}
          heading="Call Failed"
          infoDetail={{
            heading:
              _.get(auth, "userData.UserData.credits", 0) === 0
                ? "Your calls credits are finished for today"
                : "You don't have enough credits to make calls to all candidates.",
            innerContent:
              _.get(auth, "userData.UserData.credits", 0) === 0
                ? "it will be available tomorrow."
                : "You can try calling candidates one by one.",
          }}
          icon={WarningInfoRedIcon}
          handleSubmit={() => {
            setIsAddCreditsPopup(false);
          }}
          buttonClass="max-w-[200px]"
          buttonText="Upgrade Plan"
        />
      )}

      <TranscriptionDetail
        open={transcriptionDetail}
        setOpen={setTranscriptionDetail}
        selectedCandidate={selectedCompany}
      />
      {deleteCompanyPopup && (
        <DeleteAccount
          open={deleteCompanyPopup}
          setOpen={setDeleteCompanyPopup}
          handleSubmit={handleDeleteCompany}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: `Remove ${selectedCompany?.name} from ${
              removeFromStrongCompany ? "strong" : ""
            } companies list?`,
          }}
        />
      )}
      {moreVertMenuAnchorEl && (
        <MenuPopper
          open={Boolean(moreVertMenuAnchorEl)}
          anchorRef={moreVertMenuAnchorEl}
          handleClose={() => {
            setMoreVertMenuAnchorEl(null);
          }}
          menuItems={[
            {
              id: "edit_candidate_details",
              label: "Edit Candidate Details",
              icon: EditIcon,
            },
            {
              id: "edit_call",
              label: "Edit Prompt",
              icon: EditIcon,
            },
            {
              id: "view_cv",
              label: "View CV",
              icon: EditIcon,
            },
            {
              id: "remove",
              label: "Remove",
              icon: TrashIcon,
            },
          ]}
          handleMenuClick={(id) => handleMoreVertMenuClick(id)}
        />
      )}
      {confirmDelete && (
        <DeleteAccount
          open={confirmDelete}
          setOpen={setConfirmDelete}
          handleSubmit={handleDeleteCandidate}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: `Are you sure you want to delete candidate?`,
          }}
        />
      )}
      {filterAnchorEl && (
        <CandidateFilters
          anchorEl={filterAnchorEl}
          handleClose={() => setFilterAnchorEl(null)}
          handleSaveFilter={(appliedFilters) =>
            handleSaveFilter(appliedFilters)
          }
          appliedFilters={appliedFilters}
          module="candidate"
        />
      )}
      <div className="py-4 sm:py-6 lg:py-8 px-4 sm:px-6 lg:px-10 h-[calc(100vh_-_75px)]">
        <div className="h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-hidden">
          <div className="w-full h-full flex flex-col bg-[#FDFEFF] rounded-xl">
            {mainLoader ? (
              <Loader />
            ) : (
              <div className="h-full w-full bg-white rounded-xl flex flex-col">
                <div
                  className={`px-8 pt-4 ${
                    isCompanyCallSummary
                      ? "pb-[92px]"
                      : tabValue === 1
                      ? "pb-0"
                      : "py-4"
                  } flex flex-col h-full ${
                    isCompanyCallSummary ? "overflow-hidden" : ""
                  }`}
                >
                  <div className="flex justify-between items-center mb-3">
                    <button
                      className="flex items-center gap-1 text-[#121212] text-sm font-semibold leading-[19px]"
                      onClick={() => handleBackClick()}
                    >
                      <img src={ArrowBackIcon} alt="back-arrow" />
                      Back
                    </button>
                    {!isCompanyCallSummary && (
                      <div className="flex gap-2">
                        <button
                          className="flex items-center gap-1 text-[#121212] text-sm font-semibold leading-[19px]"
                          onClick={(event) => {
                            setCandidateListDropdownMenu(event.currentTarget);
                            fetchCompaniesCandidateList();
                          }}
                        >
                          {selectedCandidateDetail?.name}
                          <img src={ArrowRight} alt="back-arrow" />
                        </button>
                        <div
                          className="p-1 cursor-pointer"
                          onClick={(event) =>
                            setMoreVertMenuAnchorEl(event.currentTarget)
                          }
                        >
                          <img src={HorizontalDots} alt="cheveron" />
                        </div>
                      </div>
                    )}
                  </div>

                  {isCompanyCallSummary ? (
                    <CompanySummaryDetail
                      selectedCompany={selectedCompany}
                      selectedCandidateDetail={selectedCandidateDetail}
                      isFromSingleCompany={isFromSingleCompany}
                      deleteCompany={handleDeleteCompany}
                      handleExecuteCall={() =>
                        triggerAICall(selectedCompany?.id)
                      }
                    />
                  ) : (
                    <Fragment>
                      <CandidateList
                        candidate={selectedCandidateDetail}
                        isFromCandidateDetail={true}
                      />
                      <TabsComponent
                        tabValue={tabValue}
                        setTabValue={(newValue) => {
                          setTabValue(newValue);
                          newValue === 1
                            ? navigate(`/candidate/${candidateId}/ai-chat`)
                            : navigate(`/candidate/${candidateId}`);
                        }}
                        tabItems={[
                          { label: "Company List" },
                          { label: "AI Chat" },
                        ]}
                        isFromJobDetail={true}
                      />
                      {tabValue === 0 ? (
                        <Fragment>
                          {/* Filters and Actions */}
                          <div className="w-full flex gap-3 items-center mt-5 mb-4 justify-between xl:flex-nowrap flex-wrap">
                            <div className="w-auto flex items-center gap-3 justify-start">
                              <h6 className="text-[20px] font-medium leading-6 text-[#353535] whitespace-nowrap">
                                Companies List
                              </h6>
                            </div>
                            <div className="w-full flex flex-col xl:justify-end justify-start items-start md:flex-row md:items-center gap-3 flex-wrap">
                              <div
                                className="cursor-pointer border border-[#E5E5E5] rounded-md min-w-9 h-9 p-[10px]"
                                onClick={() => setUploadCompany(true)}
                              >
                                <img
                                  src={UserUploadPrimary}
                                  alt="userUpload"
                                  width={16}
                                  height={16}
                                />
                              </div>
                              <div
                                className={`w-full md:max-w-[232px] flex items-center gap-1 border-[1.2px] border-[#e5e5e5] rounded-lg py-2 pl-[10px]`}
                              >
                                <img src={SearchBlue} alt="search-icon" />
                                <input
                                  type="text"
                                  placeholder="Search for Companies"
                                  value={searchValue}
                                  className="text-sm leading-5 font-normal text-[#121212] border-none focus-visible:outline-none"
                                  onChange={(e) =>
                                    handleSearchCompany(e.target.value)
                                  }
                                />
                              </div>
                              <button
                                onClick={(event) =>
                                  setFilterAnchorEl(event.currentTarget)
                                }
                                className="md:w-fit w-full flex items-center px-2 md:px-4 py-2 rounded-md  border border-[#e2e2e2] text-[#121212] text-[13px] font-normal leading-4"
                              >
                                <img
                                  src={FilterIcon}
                                  alt="filter-icon"
                                  width={18}
                                  height={18}
                                />
                                Filters
                              </button>
                              <button
                                className="md:w-auto w-full bg-custom-primary-dark text-white rounded-md px-4 py-[10px] h-[36px] text-[13px] font-medium leading-4 whitespace-nowrap disabled:opacity-75"
                                onClick={() => handleDialAllCall()}
                                disabled={btnLoading === "dial_all_call"}
                              >
                                Call Everyone
                              </button>
                              <button
                                className="md:w-auto w-full bg-[#F83A3A] text-white rounded-md px-4 py-[10px] h-[36px] text-[13px] font-medium leading-4 whitespace-nowrap disabled:opacity-75"
                                onClick={() => handleStopAllCalls()}
                              >
                                Stop All Calls
                              </button>
                            </div>
                          </div>
                          {/* Candidate Table Part */}
                          <div
                            className={`flex-grow ${
                              companiesList?.length === 0 && !loading
                                ? "overflow-auto scrollbar"
                                : "overflow-hidden"
                            }`}
                          >
                            {companiesList?.length === 0 && !loading ? (
                              <CandidateEmptyScreen
                                handleRefresh={() => fetchCompanies()}
                                columns={tableColumns}
                                module="candidate"
                              />
                            ) : (
                              <CustomizedTable
                                columns={tableColumns}
                                totalCount={totalCompanies}
                                handlePageChange={(e, page) =>
                                  handlePageChange(page)
                                }
                                currentPage={currentPage}
                                selectedCandidates={selectedCandidates}
                              >
                                {loading
                                  ? [0, 1, 2, 3, 4].map((_, index) => (
                                      <CompanyListDetailSkelaton />
                                    ))
                                  : companiesList.map((row, index) => {
                                      const summary = row?.summary
                                        ? JSON.parse(row?.summary || {})
                                        : null;

                                      const statusDetail = callStatusDetail(
                                        row?.callStatus
                                      );
                                      const isCancelCallDisabled =
                                        row.callStatus === "in-progress" ||
                                        row.callStatus === "ringing" ||
                                        row.callStatus === "triggered" ||
                                        row.callStatus === "triggering";
                                      return (
                                        <StyledTableRow
                                          key={index}
                                          onClick={() =>
                                            handleCompanyClick(row)
                                          }
                                          className="cursor-pointer"
                                        >
                                          <StyledTableCell
                                            component="td"
                                            scope="row"
                                            className="w-[20%] p-2"
                                          >
                                            <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap text-[#353535] text-[13px] font-normal leading-5">
                                              {row?.name || ""}
                                            </div>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="w-[20%] p-2"
                                            component="td"
                                            scope="row"
                                          >
                                            {editingRow === row.id ? (
                                              <div
                                                className="flex items-center flex-col gap-1 common-tel-phone detail-table"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                              >
                                                <div className="flex items-center gap-1">
                                                  <PhoneNumberInput
                                                    handleFieldChange={(
                                                      value
                                                    ) =>
                                                      handlePhoneNumberChange(
                                                        value
                                                      )
                                                    }
                                                    value={editedPhoneNumber}
                                                    placeholder="Ex +44 7700 900000"
                                                  />
                                                  <button
                                                    onClick={() =>
                                                      handleSavePhoneNumber(row)
                                                    }
                                                    disabled={savingPhoneNumber}
                                                    className="disabled:opacity-50"
                                                  >
                                                    <CheckIcon
                                                      fontSize="small"
                                                      className="text-green-500"
                                                    />
                                                  </button>
                                                  <button
                                                    className="disabled:opacity-50"
                                                    onClick={handleCancelEdit}
                                                    disabled={savingPhoneNumber}
                                                  >
                                                    <CloseIcon
                                                      fontSize="small"
                                                      className="text-red-500"
                                                    />
                                                  </button>
                                                </div>
                                                {phoneNumberError && (
                                                  <div className="text-[11px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                                                    {phoneNumberError}
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div
                                                className="w-fit m-auto flex items-center gap-1 group relative cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  handleEditClick(row);
                                                }}
                                              >
                                                {row.phoneNumber}
                                                <button className="opacity-0 group-hover:opacity-100 w-0 group-hover:w-fit transition-opacity duration-200">
                                                  <img
                                                    src={EditIcon}
                                                    alt="EditIcon"
                                                  />
                                                </button>
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="w-[20%] p-2"
                                            component="td"
                                            scope="row"
                                          >
                                            <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap flex justify-center gap-1 text-[#353535] text-[13px] font-normal leading-5">
                                              <img
                                                src={MapPinIcon}
                                                width={18}
                                                height={18}
                                              />
                                              {row.location}
                                            </div>
                                          </StyledTableCell>
                                          <StyledTableCell className="w-[20%] p-2">
                                            {/* category_reason */}
                                            <CustomTooltip
                                              title={
                                                summary?.category_reason || ""
                                              }
                                              arrow
                                              placement="top-end"
                                            >
                                              <div
                                                className={`${categoryChipColor(
                                                  row?.category,
                                                  row.callStatus
                                                )} font-medium py-1 px-4 rounded-[20px] w-fit m-auto capitalize`}
                                              >
                                                {renderCategoryValue(
                                                  row.category,
                                                  row.callStatus
                                                )}
                                              </div>
                                            </CustomTooltip>
                                          </StyledTableCell>
                                          <StyledTableCell className="w-[20%] p-2">
                                            <div className="flex items-center justify-center gap-2 px-4">
                                              <CustomTooltip
                                                title={statusDetail.tooltip}
                                                arrow
                                                placement="top-end"
                                              >
                                                <button
                                                  className={`${statusDetail.color} w-[150px] text-ellipsis overflow-hidden whitespace-nowrap border rounded-md border-rounded-sm flex items-center gap-2 px-3 py-2`}
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    triggerAICall(row.id);
                                                  }}
                                                  disabled={
                                                    ![
                                                      "pending",
                                                      "busy",
                                                      "no-answer",
                                                      "failed",
                                                    ].includes(row.callStatus)
                                                  }
                                                >
                                                  <img
                                                    src={statusDetail.icon}
                                                    alt="phone-icon"
                                                  />
                                                  {statusDetail.text}
                                                </button>
                                              </CustomTooltip>
                                              <div
                                                className="flex cursor-pointer items-center justify-center p-2 border border-[#E5E5E5] rounded border-solid w-8 h-8"
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  event.stopPropagation();
                                                  setMenuPopperRef(
                                                    event.currentTarget
                                                  );
                                                  setSelectedCompany(row);
                                                }}
                                              >
                                                <img
                                                  src={HorizontalDots}
                                                  alt="horizontal-dots"
                                                  className="w-4 h-4"
                                                />
                                              </div>
                                              {[
                                                "in-progress",
                                                "ringing",
                                                "triggered",
                                                "triggering",
                                                "queue",
                                                "initiated",
                                                "ready-to-call",
                                              ].includes(row?.callStatus) && (
                                                <CustomTooltip
                                                  title={
                                                    isCancelCallDisabled
                                                      ? "Can't cancel call in Progress"
                                                      : "Cancel Call"
                                                  }
                                                  arrow
                                                  placement="top"
                                                >
                                                  <div
                                                    className={`flex cursor-pointer items-center justify-center p-2 border border-[#E5E5E5] rounded border-solid w-8 h-8`}
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      event.stopPropagation();
                                                      !isCancelCallDisabled &&
                                                        handleCancelSingleCall(
                                                          row?.id
                                                        );
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        isCancelCallDisabled
                                                          ? PhoneMissedGrayIcon
                                                          : PhoneMissedSecondaryIcon
                                                      }
                                                      alt="cancel-call"
                                                      className="w-4 h-4"
                                                    />
                                                  </div>
                                                </CustomTooltip>
                                              )}
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    })}
                              </CustomizedTable>
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        <CompanyCandidateAIChat />
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
