import { useState, forwardRef, useEffect } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CompletedGreen, CrossIcon } from "assets";
import InfoDialog from "./InfoDialog";
import { updatePhoneNumber } from "services/Auth";
import PhoneNumberInput from "component/PhoneNumberField";
import { validatePhoneNumber } from "utils/common";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddPhoneNumber = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [errors, setErrors] = useState({});

  const initialValues = {
    phone: "+44", // Default to UK country code
  };
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (!open) {
      setErrors({});
      setFormValues(initialValues);
    }
  }, [open]);

  const handleClose = () => setOpen(false);

  const handleFieldChange = (fieldName, value) => {
    const newValues = { ...formValues, [fieldName]: value };
    setFormValues(newValues);

    let newErrors = { ...errors };

    if (fieldName === "phone") {
      if (!value) {
        newErrors.phone = "Phone number is required";
      } else {
        newErrors.phone = validatePhoneNumber(value);
      }
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formValues.phone) {
      newErrors.phone = "Phone number is required";
    } else {
      newErrors.phone = validatePhoneNumber(formValues.phone);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        await updatePhoneNumber({ phone: formValues.phone });
        setLoading(false);
        setInfoDialog(true);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.title) {
          setErrors({ submit: err?.response?.data?.title });
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      PaperProps={{ style: { maxWidth: "564px", borderRadius: "12px" } }}
    >
      <DialogContent className="!py-[52px] !px-[92px] relative">
        {infoDialog && (
          <InfoDialog
            open={infoDialog}
            setOpen={handleClose}
            infoDetail={{
              heading: "Phone number added successfully.",
              innerContent: "",
            }}
            handleSubmit={handleClose}
            icon={CompletedGreen}
          />
        )}
        <img
          src={CrossIcon}
          alt="crossIcon"
          className="ml-auto cursor-pointer absolute top-4 right-4"
          onClick={handleClose}
        />
        <form
          onSubmit={handleSubmitForm}
          className="flex flex-col font-hankengrotesk"
        >
          <div className="text-[28px] leading-9 font-semibold text-left mb-5 text-[#353535]">
            Add Phone Number
          </div>
          <div className="flex flex-col gap-[18px] common-tel-phone">
            <PhoneNumberInput
              handleFieldChange={(value) => handleFieldChange("phone", value)}
              value={formValues.phone}
              placeholder="Ex +44 7700 900000"
            />
            {errors.phone && (
              <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                {errors.phone}
              </div>
            )}
          </div>
          {errors?.submit && (
            <div className="w-full text-sm font-medium text-[#FF352A] mt-[22px] mb-3">
              {errors.submit}
            </div>
          )}
          <div className="w-full flex justify-between flex-row gap-3 sm:gap-0 mt-11">
            <button
              type="button"
              onClick={handleClose}
              className="btn bg-white border-custom-primary border-[1px] text-black border-purple font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn bg-custom-primary text-white font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddPhoneNumber;
