import React, { Fragment, useState } from "react";
import { UploadIcon, CheckIconBlue, DeleteIconRed } from "assets";

export default function MultipleFileUpload({
  setUploadedFiles,
  setFileList,
  fileList,
  addMoreBtnDisabled,
}) {
  const [error, setError] = useState("");

  const MAX_FILES = 10;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    const newFiles = [];
    const errors = [];

    files.forEach((file) => {
      if (!file.name.toLowerCase().endsWith(".pdf")) {
        errors.push(`File "${file.name}" is not a valid PDF.`);
        return;
      }

      const isDuplicate = fileList.some(
        (existingFile) => existingFile.name === file.name
      );
      if (isDuplicate) {
        return;
      }
      newFiles.push(file);
    });

    const totalFiles = fileList.length + newFiles.length;
    if (totalFiles > MAX_FILES) {
      const remainingSlots = MAX_FILES - fileList.length;
      const limitedFiles = newFiles.slice(0, remainingSlots);
      setFileList((prev) => [...prev, ...limitedFiles]);
      setUploadedFiles((prev) => [...prev, ...limitedFiles]);
    } else {
      setFileList((prev) => [...prev, ...newFiles]);
      setUploadedFiles((prev) => [...prev, ...newFiles]);
    }

    setError(errors.join(" "));
  };

  const handleFileRemove = (index) => {
    const updatedFiles = fileList.filter((_, i) => i !== index);
    setFileList(updatedFiles);
    setUploadedFiles(updatedFiles);
  };

  return (
    <div>
      {fileList.length === 0 ? (
        <label
          htmlFor="multi-file-upload"
          className="border border-dashed border-[#35353599] bg-[#F9F9F9] rounded-lg min-h-[220px] flex flex-col justify-center items-center cursor-pointer"
        >
          <input
            id="multi-file-upload"
            type="file"
            accept=".pdf"
            className="hidden"
            multiple
            onChange={handleFileChange}
          />
          <div className="flex flex-col justify-center items-center">
            <img src={UploadIcon} alt="Upload Icon" width={20} height={20} />
            <span className="block text-sm font-medium text-[#121212] mt-2">
              Drag & Drop your files here or Browse files
            </span>
            <p className="text-xs text-[#353535]">(Files must be in .PDF)</p>
          </div>
        </label>
      ) : (
        <div className="mt-4">
          {fileList.map((file, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-[#E8EDF6] py-[14px] px-3 mb-2 w-full rounded-lg"
            >
              <div className="w-[calc(100%_-_38px)] flex items-center gap-[10px]">
                <img src={CheckIconBlue} alt="Check Icon" />
                <span className="w-full text-sm text-gray-600 text-ellipsis whitespace-nowrap overflow-hidden">
                  {file.name}
                  {/* ({(file.size / 1024).toFixed(2)} KB) */}
                </span>
              </div>
              <div
                className="w-7 h-7 flex justify-center items-center cursor-pointer"
                onClick={() => handleFileRemove(index)}
              >
                <img
                  src={DeleteIconRed}
                  alt="Delete Icon"
                  width={28}
                  height={28}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-col">
        {fileList.length > 0 && fileList.length < MAX_FILES && (
          <div className="w-fit mt-4">
            <label
              htmlFor="multi-file-upload"
              className="block cursor-pointer w-full px-4 py-2 border border-custom-primary text-black bg-white rounded-lg disabled:opacity-75 text-center"
            >
              Add More Files
              <input
                id="multi-file-upload"
                type="file"
                accept=".pdf"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
            </label>
          </div>
        )}
        {error && (
          <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] mt-2">
            {error}
          </span>
        )}
      </div>
    </div>
  );
}
