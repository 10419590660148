import React, { Fragment, useEffect, useState } from "react";
import Papa from "papaparse";
import {
  CrossIcon,
  UploadIcon,
  FileUploadIcon,
  FileUploadSkeleton,
  CheckIconBlue,
  DeleteIconRed,
} from "assets";
import { formatNumberInProperForm, resetUploadedFileValue } from "utils/common";
import * as XLSX from "xlsx";

export default function FileUploadV2({
  fileInfo,
  setFileInfo,
  setUploadedDetails,
}) {
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      resetUploadedFileValue();
      setError("");
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate the file type
      const validExtensions = [".csv", ".xlsx", ".xls"];
      const isValidFile = validExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );

      if (!isValidFile) {
        setError("Only CSV and Excel files (.csv, .xlsx, .xls) are allowed");
        setFileInfo({ name: "", size: 0 });
        return;
      }

      if (file.name.endsWith(".csv")) {
        handleCSVFile(file);
      } else {
        handleExcelFile(file);
      }

      // // Parse the CSV file
      // Papa.parse(file, {
      //   header: true,
      //   complete: (results) => {},
      //   error: () => {
      //     setError("There was an error parsing the CSV file");
      //     setFileInfo({ name: "", size: 0 });
      //   },
      // });
    } else {
      setFileInfo({ name: "", size: 0 });
    }
  };

  const handleExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        processFileData(jsonData, file, "excel");
      } catch (error) {
        setError("There was an error parsing the Excel file");
        setFileInfo({ name: "", size: 0 });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCSVFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        processFileData(results.data, file, "csv");
      },
      error: () => {
        setError("There was an error parsing the CSV file");
        setFileInfo({ name: "", size: 0 });
      },
    });
  };

  const processFileData = (data, file, type = "csv") => {
    // const headers = results.meta.fields.map((header) =>
    //   header.trim().toLowerCase()
    // );
    const headers = Object.keys(data[0] || {}).map((header) =>
      header.trim().toLowerCase()
    );
    const requiredHeaders = ["companyname", "location", "phonenumber"];
    //
    // Check if all required headers are present
    const missingHeaders = requiredHeaders.filter(
      (header) => !headers.includes(header)
    );

    if (missingHeaders.length > 0) {
      if (type === "csv") {
        setError(
          `CSV file is missing the following required headers: ${missingHeaders.join(
            ", "
          )}`
        );
      } else {
        setError(
          `Excel file (Sheet 1) is missing the following required headers: ${missingHeaders.join(
            ", "
          )}. Please ensure all required headers and data are present in the first sheet.`
        );
      }
      setFileInfo({ name: "", size: 0 });
    } else {
      const sizeInKB = (file.size / 1024).toFixed(2);
      setFileInfo({ name: file.name, size: sizeInKB });
      setError("");
      //
      let jsonData = data.map((row) => ({
        name:
          row["companyname"] ||
          row["companyName"] ||
          row["CompanyName"] ||
          row["company_name"] ||
          row["company-name"] ||
          row["company name"] ||
          row["Company Name"] ||
          "",
        location: row["location"] || "",
        phoneNumber: (
          row["phonenumber"] ||
          row["phoneNumber"] ||
          row["PhoneNumber"] ||
          row["phone_number"] ||
          row["phone-number"] ||
          row["phone number"] ||
          row["Phone Number"] ||
          row["phone"] ||
          row["mobile"] ||
          row["mobile_number"] ||
          row["mobile-number"] ||
          row["mobile number"] ||
          row["Mobile Number"] ||
          row["contact"] ||
          row["contact_number"] ||
          row["contact-number"] ||
          row["contact number"] ||
          row["Contact Number"] ||
          row["tel"] ||
          row["telephone"] ||
          ""
        )
          .toString()
          .replace(/\s+|[()]/g, ""), // Remove spaces and brackets
      }));

      jsonData = formatNumberInProperForm(jsonData);

      // Filter out rows with empty values or invalid phone numbers
      const validJsonData = jsonData
        .filter((row) => {
          if (!row.name || !row.location || !row.phoneNumber) return false;

          const phoneValue = row.phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters

          // UK Validation: 10 digits, or starts with 0, 44, or +44 followed by 10 digits
          const isValidUK = /^(\d{10}|0\d{10}|44\d{10}|\+44\d{10})$/.test(
            phoneValue
          );

          // India Validation: Must start with +91 and be exactly 13 characters
          const isValidIndia =
            phoneValue.startsWith("+91") && phoneValue.length === 13;

          // Spain Validation: 9-digit phone numbers allowed
          const isValidSpain = /^(\d{9}|34\d{9}|\+34\d{9})$/.test(phoneValue);

          return isValidUK || isValidIndia || isValidSpain;
        })
        .map((row) => {
          let formattedPhone = row.phoneNumber;
          const phoneValue = formattedPhone.replace(/\D/g, ""); // Remove non-numeric characters

          // **Format phone numbers correctly based on country**
          if (phoneValue.startsWith("91")) {
            // starts with 91, assume it's an Indian number
            formattedPhone = `+${phoneValue}`;
          } else if (phoneValue.startsWith("34")) {
            // starts with 34, assume it's a Spanish number
            formattedPhone = `+${phoneValue}`;
          } else if (phoneValue.startsWith("44")) {
            // starts with 44, format as UK number
            formattedPhone = `+${phoneValue}`;
          } else if (phoneValue.length === 10) {
            // 10-digit number without a country code, assume UK
            formattedPhone = `+44${phoneValue}`;
          } else if (phoneValue.length === 9) {
            // 10-digit number without a country code, assume Spain
            formattedPhone = `+34${phoneValue}`;
          }

          return {
            ...row,
            phoneNumber: formattedPhone,
          };
        });
      //
      console.log("validJsonData", validJsonData);

      if (validJsonData.length === 0) {
        setError(
          "Some rows have invalid fields or possibly invalid phone numbers. Please check your CSV file."
        );
        setFileInfo({ name: "", size: 0 });
      } else {
        setUploadedDetails(validJsonData);
        setError("");
      }
    }
  };

  const handleFileRemove = () => {
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    setFileInfo({ name: "", size: 0 });
    setError(""); // Clear error on file removal
  };

  return (
    <Fragment>
      <div class="relative w-full h-full min-h-[192px] rounded-lg bg-dashed-line">
        <div className="absolute inset-[0px] rounded-lg p-[1px]">
          <label
            htmlFor="file-upload"
            className="h-full bg-[#F9F9F9] rounded-lg flex flex-col justify-center items-center cursor-pointer"
          >
            <input
              id="file-upload"
              type="file"
              accept=".csv,.xlsx,.xls"
              className="hidden"
              onChange={handleFileChange}
            />
            {fileInfo.name ? (
              <div className="w-full h-full flex justify-center items-center relative z-10">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0">
                  <img src={FileUploadSkeleton} alt="File Upload Skeleton" />
                </div>
                <div className="flex justify-between items-center bg-[#E8EDF6] py-[14px] px-3 w-full max-w-[344px] rounded-lg relative z-10">
                  <div className="flex items-center w-[calc(100%_-_38px)] gap-[10px]">
                    <img src={CheckIconBlue} alt="CheckIconBlue" />
                    <span className="w-[calc(100%_-_24px)] text-sm text-gray-600 text-ellipsis whitespace-nowrap overflow-hidden">
                      Selected file: {fileInfo.name} ({fileInfo.size} KB)
                    </span>
                  </div>
                  <div
                    className="w-7 h-7 flex justify-center items-center"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click event from triggering the file input
                      e.preventDefault();
                      handleFileRemove();
                    }}
                  >
                    <img
                      src={DeleteIconRed}
                      alt="CancelIcon"
                      className="w-full h-full"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <img src={UploadIcon} alt="UploadIcon" width={20} height={20} />
                <span className="block text-sm font-medium text-[#121212] mt-2">
                  Drag & Drop your file here or Browse file
                </span>
                <p className="text-xs text-[#353535]">
                  (File must be in CSV or Excel format)
                </p>
              </div>
            )}
          </label>
        </div>
      </div>
      {error && (
        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {error}
        </span>
      )}
    </Fragment>
  );
}
